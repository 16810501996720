@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Sacramento&display=swap");
* {
  font-family: "Poppins";
}

body {
  overflow-x: hidden;
}

.custom-li::marker {
  font-size: .95rem;
}

.WinHeading {
  color: rgb(49, 125, 218);
  font-weight: 700;
}
.bgHeroSection,
.bgImgLeauge {
  background-image: url("Images/HeroSectionBgImg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.bgthirdSection {
  background-image: url("Images/BgSectionsecond.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.bgImgLeauges {
  background-image: url("Images/BgSectionsecond.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  height: 92%;
}
.BtnBgColor {
  background-color: rgba(217, 141, 48, 1);
  transition: transform 0.5s;
}
.BtnBgColor:hover {
  background-color: white !important;
  color: black !important;
  transform: translateY(0) scale(0.9);
}
.headingColor {
  color: rgba(217, 141, 48, 1);
}
.HeroSectionHeading {
  font-weight: 700 !important;
  font-size: 40px !important;
}
.heroSectionContent {
  height: 70vh;
}
.heroSectionContent > div > p {
  max-width: 500px;
  font-size: 18px !important;
  font-weight: 400 !important;
}
.btnHeroSection {
  font-weight: 600;
  font-size: 12px;
}
.btnHeroSectionContent {
  font-size: 20px;
  font-weight: 600;
}
.FeaturesHeading,
.LiveGamesHeading {
  font-size: 30px !important;
  font-weight: 700 !important;
}
.FeaturesCard,
.FeaturesCarded {
  background-color: rgba(30, 30, 30, 1);
  transition: box-shadow 0.5s, margin-top 0.5s;
}
.FeaturesCarded:hover {
  box-shadow: 1px 10px 80px rgba(0, 0, 0, 0.6);
}
.FeaturesCard:hover {
  margin-top: -25px;
}
.CardHeading {
  font-weight: 700 !important;
  font-size: 14px;
  text-transform: uppercase;
}
.CardLine {
  color: rgb(228, 228, 228);
  width: 50%;
}
.FeaturesCard > p {
  font-size: 11px;
  font-weight: 300;
}
.FeaturesEndingPara {
  font-size: 20px;
  font-weight: 400 !important;
}
.btnPatchSectionContent {
  font-weight: 600;
  font-size: 16px;
}
.BetaCardHeading {
  font-weight: 700 !important;
  font-size: 20px;
  text-transform: uppercase;
}
.ChampionCardWR {
  font-weight: 600;
  font-size: 14px;
}
.navbar-brand {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.searchInput {
  position: relative !important;
  padding-right: 110px;
}
.headerSelect {
  position: absolute;
  right: 16%;
  top: 13px;
  border: 0;
}
.socialIconsUl {
  text-decoration: none !important;
  list-style: none;
}
.headerPoint {
  background: none !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
}
.nav-link {
  font-weight: 400;
  color: white;
}
.nav-link:hover {
  color: rgba(12, 209, 144, 0.459);
}
.active {
  color: rgba(217, 141, 48, 1) !important;
  font-weight: 700;
}
.RobotParent {
  margin-top: 90px;
}
/* TABS */
.activeTab {
  display: block;
}
.tabNone {
  display: none;
}
.tabsBtn {
  background-color: white;
  width: 100%;
}

.tabsBtn:hover {
  background-color: rgba(217, 141, 48, 1) !important;
  color: white;
}
.activate {
  background-color: rgba(217, 141, 48, 1);
  color: white;
  font-weight: 600 !important;
}
.PersonalStatsParent {
  border-right: 1px solid rgba(198, 198, 198, 1);
}
.PersonalStatsParent > input {
  border: none !important;
  padding-inline: 0 !important;
}
.PersonalStatsParent > select {
  border: none;
  padding-right: 20px;
  font-weight: 500;
}
.LabelTabs {
  color: #245085;
  font-weight: 700;
}
.WinBtn {
  color: rgba(36, 80, 133, 1);
  font-weight: 800;
  font-size: 20px;
}
.WinBtn:hover {
  color: black;
}
.Vsheading {
  font-weight: 800;
  color: white;
  font-size: 20px;
}
input {
  width: 95% !important;
}
select:hover {
  cursor: pointer;
}
/* TABSEND */
.timeSpan {
  color: rgb(83, 47, 47);
  font-weight: 700;
}
.rankedSolo {
  color: #245085;
  font-weight: 600;
}
.category {
  font-weight: 700;
  color: rgba(63, 63, 63, 1);
}
.LiveGames {
  background-color: #245085;
}
.footerHeading {
  font-size: 40px;
  font-weight: 700;
}
.footerLinks {
  text-decoration: none;
  font-weight: 300;
  font-size: 14px;
}
.footerLinks:hover {
  color: #245085 !important;
}
.footerHr {
  background-color: rgba(252, 252, 252, 1);
  height: 2px;
}
.footerContent {
  border-bottom: 1px solid #fcfcfc !important;
}
.major-shadow {
  box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .HeroSectionHeading {
    font-size: 24px !important;
  }
  .heroSectionContent {
    height: 100%;
  }
  .heroSectionContent > div > p {
    font-size: 16px !important;
  }
  .btnHeroSectionContent {
    font-size: 12px;
  }
  .FeaturesHeading {
    font-size: 24px !important;
    text-align: center;
  }
  .bgImgLeauges {
    height: fit-content;
  }
  .headerSelect {
    position: absolute;
    right: 10%;
    top: 40%;
  }
}

@media only screen and (max-width: 1500px) {
  /* .container {
    width: 70vw;
  }
  .container-fluid {
    width: 80vw;
  } */
}
