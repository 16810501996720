@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-primary-800;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
}

.texture-bg {
  background: url("images/texture-bg.png");
  background-position: center;
  background-repeat: repeat;
}

.title-shadow {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-shadow {
  box-shadow: 0px 0px 40px 20px rgba(0, 0, 0, 0.3);
}

/* Header Buttons */
.button-type-yellow-bg {
  @apply bg-winions-orange rounded-[10px];
}

.button-type-underline {
  @apply rounded-[10px] underline;
}

/* Hero Buttons */
.button-type-black-bg {
  @apply font-medium bg-gray-900 text-white rounded-[55px] text-base py-5 px-9;
}

/* Simple Yellow Button */
.button-type-yellow {
  @apply bg-winions-orange text-white rounded-[58px] text-sm py-3 px-14 text-[19px];
}

.button-type-yellow-bg-large {
  @apply font-medium bg-winions-orange rounded-[55px] text-sm py-4 px-8;
}

/* Large Yellow Button */
.button-type-yellow-bg-xl {
  @apply font-medium bg-winions-orange rounded-[55px] text-base lg:text-[18px] py-5 lg:py-6 px-12 lg:px-12;
}

.button-type-yellow-bg-sm {
  @apply font-medium bg-winions-orange rounded-[58px] text-sm py-2 px-52;
}

.major-shadow {
  box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.75);
}
