@keyframes slideDown {
	0% {
	  opacity: 0;
	  transform: translateY(-75%);
	}
	25% {
	  opacity: 1;
	  transform: translateY(0%);
	}
	75% {
	  opacity: 1;
	  transform: translateY(0%);
	}
	100% {
	  opacity: 0;
	  transform: translateY(75%);
	}
}